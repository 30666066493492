<template>
  <div>
    <b-modal
      id="modal-buy-confirm"
      :title="$t('reservation.confirm')"
      title-class="font-medium-4 font-weight-bolder"
      centered
      size="md"
    >
      <template #modal-footer="{ cancel }">
        <div class="d-flex justify-content-end w-100">
          <b-button
            size="md"
            variant="outline-secondary"
            class="center rounded-pill mr-2"
            @click="cancel()"
          >
            {{ $t('reservation.back') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient border-right-0"
            pill
            :disabled="!iAgree"
            @click="handleSubmit"
          >
            <div class="d-flex-center px-25">
              Xác nhận
            </div>
          </b-button>
        </div>
      </template>

      <div>
        Đại lý phải nghiêm túc <b style="color: orange">Tuân thủ quy định đặt giữ chỗ</b> của các hãng hàng không, có trách nhiệm với các booking của mình.
      </div>
      <div class="mt-50">
        Tất cả các trường hợp <b style="color: orange">Hold khống, Tên khách vô nghĩa</b>, sai quy định... AG phải xử lý nhanh nhất có thể.
      </div>
      <div class="mt-50">
        Nếu có phát sinh, BGT sẽ xử lý theo quy định.
      </div>

      <!-- ANCHOR - iAgree checkbox -->
      <b-form-checkbox
        v-model="iAgree"
        name="i-agree"
        class="mt-50"
      >
        <div class="text-body fw-600 font-medium-1">
          {{ $t('reservation.iAgree') }}
        </div>
      </b-form-checkbox>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal, BFormCheckbox,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BModal,
    BButton,
    BFormCheckbox,
  },
  props: {
    agencyToEdit: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const iAgree = ref(true)

    function handleSubmit() {
      emit('confirm-buy-package')
      this.$bvModal.hide('modal-buy-confirm')
    }

    return {
      handleSubmit,
      iAgree,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
