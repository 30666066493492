import { render, staticRenderFns } from "./ModalBuyConfirm.vue?vue&type=template&id=c9af0a42&scoped=true"
import script from "./ModalBuyConfirm.vue?vue&type=script&lang=js"
export * from "./ModalBuyConfirm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9af0a42",
  null
  
)

export default component.exports